<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <b-row>
            <b-col lg="10" offset-lg="1" sm="12">
                <b-overlay :show="loading">
                    <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                        <b-form  @submit.prevent="handleSubmit(register)" @reset.prevent="reset" >
                        <ValidationProvider name="Select Category" vid="category_id" rules="required">
                            <b-form-group
                            class="row"
                            label-cols-sm="12"
                            label-for="formData"
                            slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                            Select Category <span class="text-danger">*</span>
                            </template>
                            <b-form-select
                            :options="categoryList"
                                id="category_id"
                                rows="6"
                                v-model="formData.category_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-select>
                            <div class="invalid-feedback">
                                {{ errors[0] }}
                            </div>
                            </b-form-group>
                        </ValidationProvider>
                        <ValidationProvider name="Title" vid="title" rules="required">
                            <b-form-group
                            class="row"
                            label-cols-sm="12"
                            label-for="formData"
                            slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                            Title <span class="text-danger">*</span>
                            </template>
                            <b-form-input
                                id="title"
                                rows="6"
                                v-model="formData.title"
                                :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-input>
                            <div class="invalid-feedback">
                                {{ errors[0] }}
                            </div>
                            </b-form-group>
                        </ValidationProvider>
                        <ValidationProvider name="Required Point" vid="required_point" rules="required">
                            <b-form-group
                            class="row"
                            label-cols-sm="12"
                            label-for="formData"
                            slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                            Required Point <span class="text-danger">*</span>
                            </template>
                            <b-form-input
                                id="required_point"
                                rows="6"
                                v-model="formData.required_point"
                                :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-input>
                            <div class="invalid-feedback">
                                {{ errors[0] }}
                            </div>
                            </b-form-group>
                        </ValidationProvider>
                        <ValidationProvider name="Min Point" vid="min_point" rules="">
                            <b-form-group
                            class="row"
                            label-cols-sm="12"
                            label-for="formData"
                            slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                            Min Point
                            </template>
                            <b-form-input
                                id="min_point"
                                rows="6"
                                v-model="formData.min_point"
                                :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-input>
                            <div class="invalid-feedback">
                                {{ errors[0] }}
                            </div>
                            </b-form-group>
                        </ValidationProvider>
                        <ValidationProvider name="Thumbnail" vid="thumbnail" rules="required">
                            <b-form-group
                            class="row"
                            label-cols-sm="12"
                            label-for="formData"
                            slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                              Thumbnail Image<span class="text-danger">*</span>
                            </template>
                            <b-form-file
                                id="thumbnail"
                                rows="6"
                                @change="handleImg"
                                v-model="formData.thumbnail"
                                :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-file>
                            <div class="invalid-feedback">
                                {{ errors[0] }}
                            </div>
                            </b-form-group>
                        </ValidationProvider>
                        <ValidationProvider name="Description" vid="description" rules="required">
                            <b-form-group
                            class="row"
                            label-cols-sm="12"
                            label-for="formData"
                            slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                              Description <span class="text-danger">*</span>
                            </template>
                            <b-form-textarea
                                id="description"
                                rows="6"
                                v-model="formData.description"
                                :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-textarea>
                            <div class="invalid-feedback">
                                {{ errors[0] }}
                            </div>
                            </b-form-group>
                        </ValidationProvider>
                        <div>
                            <p class="m-0 mb-2" style="font-weight: bold">  No Task  </p>
                            <b-form-checkbox v-model="formData.no_task" size="md" name="check-button" switch>
                                <span v-if="formData.no_task">ON</span>
                                <span v-else>OF</span>
                            </b-form-checkbox>
                        </div>
                        <ValidationProvider name="Policy" vid="policies" rules="">
                            <b-form-group
                            class="row"
                            label-cols-sm="12"
                            label-for="formData"
                            slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                              Policies <span class="text-danger">*</span>
                            </template>
                                <b-form-checkbox-group
                                :state="errors[0] ? false : (valid ? true : null)"
                                v-model="formData.policies"
                                :options="getMergeCategoryLis()"
                                :aria-describedby="ariaDescribedby"
                            ></b-form-checkbox-group>
                            <div class="invalid-feedback">
                                {{ errors[0] }}
                            </div>
                            </b-form-group>
                        </ValidationProvider>
                        <div class="row">
                            <div class="col-sm-3"></div>
                            <div class="col text-right">
                            <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                            &nbsp;
                            <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-1')">Close</b-button>
                            </div>
                        </div>
                        </b-form>
                    </ValidationObserver>
                </b-overlay>
            </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import RestApi, { baseUrl } from '../../../config/api_config'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import iziToast from 'izitoast';
export default {
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  created () {
      if (this.id) {
          this.formData = this.getItem()
      }
  },
  data () {
    return {
      saveBtnName: this.id ? 'Update' : 'Save',
      formData: {
        title: '',
        category_id: '',
        required_point: '',
        thumbnail: null,
        description: null,
        min_point: null,
        description: null,
        policies: [],
      }
    }
  },
  computed: {
      policyList () {
        return this.$store.state.commonObj.policyList
      },
      categoryList () {
        return this.$store.state.commonObj.withdrawCategoryList
      },
      quizCategoryList () {
        return this.$store.state.commonObj.quizCategoryList
      },
      articleCategoryList () {
        return this.$store.state.commonObj.articleCategoryList
      },
      loading () {
        return this.$store.state.static.loading
      }
  },
  methods: {
    handleImg( event ){
        this.formData.thumbnail = event.target.files[0];
    },
    getMergeCategoryLis () {
        return this.articleCategoryList.concat(this.quizCategoryList).map(item => {
            return { value: item.text, text: item.text }
        })
    },
    getItem () {
        const item = this.$store.state.list.find(item => item.id === parseInt(this.id))
        return JSON.parse(JSON.stringify({...item, no_task: item.no_task == 1 ? true : false}))
    },
    async register () {
        this.$store.dispatch('mutedLoad', { loading: true, listReload: false })
        let result = null
        let formData = new FormData()
        Object.keys(this.formData).map(key => {
            if(key == 'no_task') {
                formData.append(key, this.formData[key] ? 1 : 0)
            } else if (key == 'policies') {
                formData.append(key, JSON.stringify(this.formData[key]))
            } else {
                formData.append(key, this.formData[key])
            }        
        })
        if (this.id) {
            formData.append('_method', 'put')

            result = await RestApi.postData(baseUrl, `${'api/withdraw-methods/update'}/${this.id}`, formData)
        } else {
            result = await RestApi.postData(baseUrl,'api/withdraw-methods/store', formData)
        }
        this.$store.dispatch('mutedLoad', { loading: false, listReload: true })
        this.$store.dispatch('dropdownLoad', { hasDropdownLoaded: false })
        if (result.success) {
            iziToast.success({
                title: 'Success',
                message: result.message
            })
             this.$bvModal.hide('modal-1')
        } else {
            this.$refs.form.setErrors(result.errors)
        }
    }
  }
}
</script>
